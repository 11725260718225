import React, { useState } from 'react';
import { GoDownload } from "react-icons/go";
import { Helmet } from 'react-helmet';
import Slider from "react-slick";


const Chaharbagh = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000
  };

  return (
    <div className="container mx-auto bg-white bg-opacity-55">
      <Helmet>
        <title>RUDA Chaharbagh & Chaharbagh Enclave | Faizan Real Estate</title>
        <meta name="description" content="Explore RUDA's Chaharbagh & Chaharbagh Enclave with Faizan Real Estate - Lahore's premium sustainable urban developments offering residential & commercial properties. Discover modern living in a smart city." />
        <meta name="keywords" content="RUDA, Ravi Urban Development Authority, Chaharbagh, Chaharbagh Enclave, Lahore real estate, sustainable development, smart city, property investment, Faizan Real Estate, residential properties, commercial spaces" />
      </Helmet>
      <div className="container mx-auto min-h-[80vh] mt-10 sm:mb-10 sm:px-5 px-2">
        <h1 className="mb-6 sm:mb-10 text-headingColor font-semibold sm:text-[2.5rem] text-[1.5rem] text-primaryColor leading-tight border-y-2 w-fit border-btn">
          RUDA <span className="sm:text-[1.75rem] text-[0.75rem] font-medium">Ravi Urban Development Authority</span>
        </h1>
        <div className="sm:flex justify-center items-center gap-6 space-y-6 sm:space-y-0">
          <div className="px-4 py-6 bg-white rounded-lg sm:w-1/2 h-auto ">
            <h2 className="sm:text-[1.75rem] text-[1rem] font-semibold text-center text-brd">Chaharbagh</h2>
            <p className="text-primaryColor sm:text-[1rem] text-sm leading-relaxed text-justify">
            In today's world, where sustainable urban development is a global trend, Chahar Bagh emerges as a frontrunner. This meticulously planned community integrates cutting-edge concepts to redefine modern living.
            <br />

Imagine a vibrant tapestry woven with residential enclaves, from luxurious high-rises and modern mid-rises to trendy condos.  Chahar Bagh caters to diverse lifestyles, all within a meticulously designed masterplan. But it doesn't stop there. Flourishing commercial spaces promise an exciting array of retail, dining, and entertainment options, fostering a dynamic hub at your doorstep.
<br />

But Chahar Bagh isn't just about aesthetics; it prioritizes connectivity.  Its strategic location places you near key transport arteries like the Lahore Ring Road, Lahore Orange Train Head Office, and the Lahore Sialkot Motorway. This seamless integration ensures effortless commutes and easy access to all corners of the city.
<br />

So, if you're searching for a smart city experience that seamlessly blends modern living with unparalleled convenience, look no further than Chahar Bagh. This trendsetting development promises an oasis where everything you need is just a stone's throw away.
            </p>
            <a href="/Chahar Bagh Phase I Master Plan for Commercial (15-02-23)-Model.pdf" download className='ml-auto  sm:py-2 py-1 px-2 text-[10px] sm:text-[17px] rounded-md sm:px-3 bg-gradient-to-r from-btn to-btnhover hover:bg-gradient-to-r hover:from-btnhover hover:to-btn transition-colors ease-linear text-white hover:bg-smallTextColor w-fit flex justify-center items-center'  > <span>Master Plan</span> <span><GoDownload /></span></a>

          </div>
          <div className="px-4 py-6 bg-white rounded-lg sm:w-1/2 h-auto ">
            <h2 className="sm:text-[1.75rem] text-[1rem] font-semibold text-center text-brd">Chaharbagh Enclave</h2>
            <p className="text-primaryColor sm:text-[1rem] text-sm leading-relaxed text-justify">
            Nestled within the visionary development of Chahar Bagh, Chahar Bagh Enclave sets a new standard for upscale living.  This isn't just a collection of plots; it's a canvas for crafting your dream home in an environment that prioritizes both comfort and eco-friendliness.
            <br />

Chahar Bagh Enclave seamlessly blends contemporary elegance with a deep commitment to sustainability. Imagine sprawling plots offering ample space to design your dream residence, all while knowing the community fosters a greener future. Whether you envision a sprawling villa or a meticulously designed modern home, Chahar Bagh Enclave caters to your desires.
<br />

This exclusive enclave positions you within a thriving community brimming with life.  The masterplan of Chahar Bagh promises a vibrant ecosystem with convenient access to commercial hubs, recreational spaces, and top-notch educational facilities, ensuring everything you need is right at your doorstep.
<br />

So, if you're seeking a lifestyle that perfectly balances luxury with a forward-thinking approach to the environment, look no further than Chahar Bagh Enclave.
 This enclave offers more than just a plot; it's a gateway to a
  life brimming with opportunity and crafted for the discerning resident.
            </p>
            <a href="/CB II.pdf" download className='ml-auto  sm:py-2 py-1 px-2 text-[10px] sm:text-[17px] rounded-md sm:px-3 bg-gradient-to-r from-btn to-btnhover hover:bg-gradient-to-r hover:from-btnhover hover:to-btn transition-colors ease-linear text-white hover:bg-smallTextColor w-fit flex justify-center items-center'  > <span>Master Plan</span> <span><GoDownload /></span></a>
          </div>
        </div>
        <div className="sm:mt-24 mt-4">
          <h2 className="sm:text-[1.75rem] text-[1rem] font-semibold text-center text-brd">Gallery</h2>
          <div className="overflow-hidden">
          <div className="slider-container overflow-hidden">
      <Slider {...settings} className='flex justify-center items-center gap-3 overflow-y-hidden'>
        <div className='ml-2'>
        <img className='cursor-pointer ' src="https://ruda.gov.pk/system/files/Image4_000_3.jpg" alt=""  />

        </div>
        <div className='ml-2'>
        <img className='cursor-pointer ' src="https://ruda.gov.pk/system/files/Image3_000_2.jpg" alt=""  />

        </div>
        <div className='ml-2'>
        <img className='cursor-pointer ' src="https://ruda.gov.pk/system/files/Image8_0.jpg" alt="" />

        </div>
        <div className='ml-2'>
        <img className='cursor-pointer ' src="https://ruda.gov.pk/system/files/Image6_3.jpg" alt=""  />

        </div>
        <div className='ml-2'>
        <img className='cursor-pointer ' src="https://ruda.gov.pk/system/files/Image5_000_2.jpg" alt=""  />

        </div>
        <div className='ml-2'>
        <img className='cursor-pointer ' src="https://ruda.gov.pk/system/files/Image10_000_0.jpg" alt="" />

        </div>
        {/* <div>
          <h3>7</h3>
        </div>
        <div>
          <h3>8</h3>
        </div>
        <div>
          <h3>9</h3>
        </div> */}
      </Slider>
    </div>
          </div>
        </div>
        
        {/* Modal */}
        {selectedImage && (
          <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-70 flex justify-center items-center z-50">
            <div className="max-w-screen-lg w-full bg-white p-6 rounded-lg">
              <img src={selectedImage} alt="Selected" className="w-full" />
              <button onClick={closeModal} className="absolute top-0 right-0 m-4 text-white text-2xl">&times;</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Chaharbagh;
