import cb10marla from '../images-videos/cb 10 Marla.jpg'
import cb1Kannal from '../images-videos/cb 1 kannal.jpg'
import cbE3M from '../images-videos/cbE3M.png'
import cbE5M from '../images-videos/cbE5M.jpg'
import cbE7M from '../images-videos/cbE7M.jpg'
import cbE1K from '../images-videos/cbE1K.jpg'
import cbE10M from '../images-videos/cbE10M.jpg'


export const navBarData = [
    {
        title: "Home",
        url: "/"
    },
    {
        title: "About Us",
        url: "/about"
    },
    {
        title: "RUDA",
        url: "/ruda"
    },
    // {
    //     title: "Packages",
    //     url: "/packages"
        
    // },
    // {
    //     title: 	'Blog',
    //     url: '/blog'
    // },
    {
        title: "Contact",
        url: "/contact"
    }
]

export const chaharbagh = [
    {
        title: "10 Marla",
        totalCost: "Rs 15,000,000",
        downPayment: "15%",
        downPaymentAmnt: "Rs 2,250,000",
        img: cb10marla

    },
    {
        title: "01 Kanal",
        totalCost: "Rs 27,500,000",
        downPayment: "15%",
        downPaymentAmnt: "Rs 4,125,000",
        img: cb1Kannal

    }

]


export const chaharbaghEnclave = [
    {
        title: "3 Marla",
        totalCost: "Rs 3,600,000",
        downPayment: "10%",
        downPaymentAmnt: "Rs 360,000",
        img: cbE3M
    },
    {
        title: "5 Marla",
        totalCost: "Rs 6,000,000",
        downPayment: "10%",
        downPaymentAmnt: "Rs 600,000",
        img: cbE5M
    },
    {
        title: "7 Marla",
        totalCost: "Rs 8,400,000",
        downPayment: "10%",
        downPaymentAmnt: "Rs 840,000",
        img: cbE7M
    },
    {
        title: "10 Marla",
        totalCost: "Rs 12,000,000",
        downPayment: "10%",
        downPaymentAmnt: "Rs 1,200,000",  
        img: cbE10M

    },
    {
        title: "1 Kanal",
        totalCost: "Rs 24,000,000",
        downPayment: "10%",
        downPaymentAmnt: "Rs 2,400,000",
        img: cbE1K

    },

]