import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './components/Home';
import About from './components/About';
import Chaharbagh from './components/Chaharbagh';
import Contact from './components/Contact';
import Error from './components/Error';
import WhatsApp from './components/WhatsApp';
import PrivacyPolicy from './components/PrivacyPolicy';

function App() {
  return (
    <Router>
      <Navbar/>
      <Routes>
        <Route element={<Home/>} path='/'/>
        <Route element={<About/>} path='/about'/>
        <Route element={<Chaharbagh/>} path='/ruda'/>
        <Route element={<Contact/>} path='/contact'/>
        <Route element={<PrivacyPolicy/>} path='/privacy-policy' />
        <Route element={<Error/>} path='*'/>
      </Routes>
      <WhatsApp/>
      <Footer/>
    </Router>
  );
}

export default App;
