import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div className='bg-nav mx-auto  h-full'>
      <div className=" mx-auto flex flex-col justify-center items-center space-y-2">
      <iframe 
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3397.964060589108!2d74.42320057535606!3d31.60744454283538!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3919110139009bed%3A0x78bbb15551f9c17e!2sFaizan%20Real%20Estate%20and%20Developers!5e0!3m2!1sen!2s!4v1715860135800!5m2!1sen!2s"
      className='w-full sm:h-[25vh] h-[25%]'  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      <h4 className='text-center text-btnhover sm:text-[1rem] text-[0.50rem] font-[500]'>Copy &copy; All Rights Reserved 2024 - <Link to='/privacy-policy' className='text-inherit no-underline'>Privacy Policy</Link> </h4>
      </div>
    </div>
  )
}

export default Footer


