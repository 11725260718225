import React from 'react'
import { FaWhatsapp } from 'react-icons/fa';

const WhatsApp = () => {
  return (
    <div>
      <div className="fixed bottom-5 right-5 z-50">
      <a href="https://wa.me/+923316345525" target="_blank" rel="noopener noreferrer">
        <FaWhatsapp className="text-4xl text-green-500 w-[55px] h-[55px] " />
      </a>
    </div>
    </div>
  )
}

export default WhatsApp
