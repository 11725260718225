import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';
import { Helmet } from 'react-helmet';

const Contact = () => {
  const form = useRef();
  const [isSending, setIsSending] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setIsSending(true);

    emailjs
      .sendForm('service_vlmvfvg', 'template_mccxqxs', form.current, 'pQDP7UwMCmmwftLYl')
      .then((result) => {
        console.log(result.text);
        form.current.reset();
        setIsSending(false);
        toast.success(Swal.fire({
          title: 'Message Sent Successfully',
          text: "Our Sales Team will contact you soon",
          confirmButtonColor: "#EE9B00",
          showClass: {
            popup: 'animate__animated animate__fadeInDown'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          }
        }));
      }, (error) => {
        console.log(error.text);
        setIsSending(false);
        toast.error(error.text);
      });
  };

  return (
    <div className='container mx-auto bg-white bg-opacity-55 overflow-y-hidden'>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact Us | Faizan Real Estate and Developers | Official Sales Partner with RUDA</title>
        <meta name="description" content="Faizan Real Estate & Developers - Your trusted partner for RUDA-approved properties in Lahore. We empower clients with expertise for seamless RUDA property sales, investment, and financing." />
      </Helmet>
      <div className='container mx-auto min-h-[80vh] px-4 py-2 mt-10 overflow-y-hidden sm:mb-10'>
        <h1
          data-aos='fade-up'
          data-aos-duration='1200'
          data-aos-delay='200'
          className='text-headingColor overflow-y-hidden font-[700] sm:text-[2.5rem] text-[1.5rem] mb-8 text-primaryColor leading-tight border-y-2 w-fit border-btn'>
          Book An Appointment
        </h1>
        <div className="grid place-items-center overflow-y-hidden">
          <div
            data-aos='fade-left'
            data-aos-duration='1500'
            data-aos-delay='300'
            className="w-full mt-8 md:mt-0 md:w-1/2 overflow-y-hidden lg:flex items-center bg-nav px-4 lg:px-8 py-8 rounded-md">
            <form className='w-full bg-inherit' ref={form} onSubmit={sendEmail}>
              <input type="text" placeholder='Your Name' className='mb-5 w-full p-3 focus:outline-none rounded-[5px]' name='from_name' required />
              <input type="number" placeholder='Your Phone Number' className='mb-5 w-full p-3 focus:outline-none rounded-[5px]' name='user_phone' required />
              <select className='mb-5 w-full p-3 focus:outline-none rounded-[5px]' name='user_city' required>
                <option value="">Select Your City</option>
                <option value="Karachi">Karachi</option>
                <option value="Lahore">Lahore</option>
                <option value="Islamabad">Islamabad</option>
                <option value="Rawalpindi">Rawalpindi</option>
                <option value="Faisalabad">Faisalabad</option>
                <option value="Multan">Multan</option>
                <option value="Peshawar">Peshawar</option>
                <option value="Quetta">Quetta</option>
                <option value="Sialkot">Sialkot</option>
                <option value="Gujranwala">Gujranwala</option>
                <option value="Hyderabad">Hyderabad</option>
                <option value="Sukkur">Sukkur</option>
                <option value="Bahawalpur">Bahawalpur</option>
                <option value="Abbottabad">Abbottabad</option>
                <option value="Mardan">Mardan</option>
                <option value="Sargodha">Sargodha</option>
                <option value="Rahim Yar Khan">Rahim Yar Khan</option>
                <option value="Chiniot">Chiniot</option>
                <option value="Sheikhupura">Sheikhupura</option>
                <option value="Jhang">Jhang</option>
                <option value="Dera Ghazi Khan">Dera Ghazi Khan</option>
                <option value="Turbat">Turbat</option>
                <option value="Kohat">Kohat</option>
                <option value="Bannu">Bannu</option>
                <option value="Mirpur Khas">Mirpur Khas</option>
                <option value="Thatta">Thatta</option>
                <option value="Gilgit">Gilgit</option>
                <option value="Skardu">Skardu</option>
              </select>
              <textarea type="text" rows={3} placeholder='Your Query' className='mb-5 w-full p-3 focus:outline-none rounded-[5px]' name='message' required />
              <button
                className='w-full p-3 focus:outline-none rounded-[5px] bg-gradient-to-r from-btn to-btnhover hover:bg-gradient-to-r hover:from-btnhover hover:to-btn transition-colors ease-linear text-white hover:bg-smallTextColor text-center duration-150'
                disabled={isSending}>
                {isSending ? 'Sending...' : 'Send'}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
