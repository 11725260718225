import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';


const Error = () => {
  return (
    <div className="container mx-auto bg-white bg-opacity-65 min-h-screen flex flex-col justify-center items-center">
      <Helmet>
        <meta charSet="utf-8" />
        <title> 404-Error | Faizan Real Estate and Developers | Official Sales Partner with RUDA</title>
        <meta name="description" content="Faizan Real Estate & Developers - Your trusted partner for RUDA-approved properties in Lahore. We empower clients with expertise for seamless RUDA property sales, investment, and financing." />
      </Helmet>
      <h1 className="text-headingColor font-bold text-4xl sm:text-6xl mb-4">404 - Page Not Found</h1>
      <p className="text-primaryColor text-lg sm:text-xl mb-8 text-center">
        Oops! The page you're looking for doesn't exist.
      </p>
      <Link
        to="/"
        className="bg-gradient-to-r from-btn to-btnhover hover:bg-gradient-to-r hover:from-btnhover hover:to-btn text-white py-3 px-6 rounded-md text-center text-lg sm:text-xl"
      >
        Go Back to Home
      </Link>
    </div>
  );
};

export default Error;
