import React, { useEffect } from 'react';
import wave from '../assets/images-videos/wave (1).svg';
import combo from '../assets/images-videos/combo-logo.jpg';
import { Link } from 'react-router-dom';
import { MdArrowRight } from 'react-icons/md';
import { Helmet } from 'react-helmet';
import Accordion from 'react-bootstrap/Accordion';
import Slider from 'react-slick';
import cbLogo from '../assets/images-videos/chaharbagh  .png'
import {chaharbagh, chaharbaghEnclave} from '../assets/data/data'
import { GoDownload } from 'react-icons/go';


const Home = () => {

  const scrollTop = () => {

    window.scrollTo(0, 0); // Scroll to the top when component mounts

  }

  var settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,  
    slidesToShow: 1,
    slidesToScroll: 1,
    
  };

  return (
    <div className='container mx-auto bg-white bg-opacity-55'>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Faizan Real Estate and Developers | Official Sales Partner with RUDA</title>
        <meta name="description" content="Faizan Real Estate & Developers - Your trusted partner for RUDA-approved properties in Lahore. We empower clients with expertise for seamless RUDA property sales, investment, and financing." />
      </Helmet>
      <div className='container mx-auto min-h-[80vh]  mt-10 '>
        <div className='w-fit sm:leading-7 leading-4 px-4 py-2'>
          <h1 className='text-headingColor font-[700] sm:text-[2.5rem] text-[1.5rem]  text-primaryColor overflow-y-hidden leading-tight  border-y-2 w-fit  border-btn'>
            Faizan <span className='sm:text-[1.75rem] text-[0.75rem] font-[500]'>Real Estate & Developers</span>
          </h1>
          <h2 className='text-end sm:text-[1rem] text-[0.50rem] font-[500] text-brd'>
            Official Sales Partner with{' '}
            <a href='https://ruda.gov.pk/' target='_blank' className='underline text-primaryColor'>
              RUDA
            </a>
          </h2>
        </div>

        <div className='mt-3 sm:mt-10  -px-4 -py-2 overflow-hidden'>
          {/* <img src='https://ruda.gov.pk/system/files/Image6_2.jpg' className='mx-auto block w-full ' alt='' /> */}
          <Slider {...settings} className='overflow-y-hidden'>
  <div className="relative">
    <div className='cursor-grab text-center'>
      <img src="https://ruda.gov.pk/system/files/Image1_000_2.jpg" alt="" />
      <h2 className="absolute inset-0 flex items-center justify-center text-white bg-btnhover sm:text-[1.75rem] text-[1rem] font-[600] rounded bg-opacity-95 px-2 py-1 w-fit h-fit mx-auto my-auto">Your Dream...</h2>
    </div>
  </div>

  <div className="relative">
    <div className='cursor-grab text-center'>
      <img src="https://ruda.gov.pk/system/files/Image3_2.jpg" alt="" />
      <h2 className="absolute inset-0 flex items-center justify-center text-white bg-btnhover sm:text-[1.75rem] text-[1rem] font-[600] rounded bg-opacity-95 px-2 py-1 w-fit h-fit mx-auto my-auto">Living, with...</h2>
    </div>
  </div>

  <div className="relative">
  <div className='cursor-grab text-center relative'>
    <img src="https://ruda.gov.pk/system/files/Image3_003.jpg" alt="" />
    <img src={cbLogo} className='absolute top-0 left-0 z-10 w-[10%] mt-1 ml-1' alt="cb logo" />
  </div>
</div>

  <div className="relative">
    <div className='cursor-grab text-center'>
      <img src="https://ruda.gov.pk/system/files/Image2_3.jpg" alt="" />
      <h2 className="absolute inset-0 flex items-center justify-center text-white bg-btnhover sm:text-[1.75rem] text-[1rem] font-[600] rounded bg-opacity-95 px-2 py-1 w-fit h-fit mx-auto mt-[7%]">Ravi Urban Development Authority</h2>
    </div>
  </div>
</Slider>

        </div>

        <div className=" mt-10">

            <h3 className='sm:text-[2.5rem] text-[1.5rem] font-[600] text-primaryColor uppercase border-y-2 w-fit italic mx-auto border-btn'>Chahar Bagh <span>!</span></h3>

              {
                chaharbagh.map(e=>(
                  <div class="flex flex-col md:flex-row justify-between sm:items-center sm:mb-5 mb-3 border-gray-200 shadow-xl border-opacity-40 border-4">
                  <div class="sm:w-1/2 bg-btnhover bg-opacity-20 px-2 py-2 sm:rounded-lg">
                      <ul className='sm:text-[1.25rem] text-[1rem] '>
                          <li className='text-nav'><strong className='text-primaryColor'>Plot Size: </strong>{e.title}</li>
                          <li className='text-nav'><strong className='text-primaryColor'>Plot Price: </strong>{e.totalCost}</li>
                          <li className='text-nav'><strong className='text-primaryColor'>Booking From: </strong>{e.downPayment}</li>
                          <li className='text-nav'><strong className='text-primaryColor'>Down Payment: </strong>{e.downPaymentAmnt}</li>
                      </ul>
            <a href="/Chahar Bagh Phase I Master Plan for Commercial (15-02-23)-Model.pdf" download className='ml-auto  sm:py-2 py-1 px-2 text-[10px] sm:text-[17px] rounded-md sm:px-3 bg-gradient-to-r from-btn to-btnhover hover:bg-gradient-to-r hover:from-btnhover hover:to-btn transition-colors ease-linear text-white hover:bg-smallTextColor w-fit flex justify-center items-center'  > <span>Master Plan</span> <span><GoDownload /></span></a>

                  </div>
                  <div class="sm:w-1/2">
                      <img src={e.img} alt="" />
                  </div>
              </div>
              
                ))
              }
        </div>

        <div className=" mt-10">

        <h3 className='sm:text-[2.5rem] text-[1.5rem] font-[600] text-primaryColor uppercase border-y-2 w-fit italic mx-auto border-btn'>Chahar Bagh Enclave<span>!</span></h3>
              {
                chaharbaghEnclave.map(e=>(
                  <div class="flex flex-col md:flex-row justify-between sm:items-center sm:mb-5 mb-3 border-gray-200 shadow-xl border-opacity-40 border-4">
                  <div class="sm:w-1/2 bg-btnhover bg-opacity-20 px-2 py-2 sm:rounded-lg">
                      <ul className='sm:text-[1.25rem] text-[1rem] '>
                          <li className='text-nav'><strong className='text-primaryColor'>Plot Size: </strong>{e.title}</li>
                          <li className='text-nav'><strong className='text-primaryColor'>Plot Price: </strong>{e.totalCost}</li>
                          <li className='text-nav'><strong className='text-primaryColor'>Booking From: </strong>{e.downPayment}</li>
                          <li className='text-nav'><strong className='text-primaryColor'>Down Payment: </strong>{e.downPaymentAmnt}</li>
                      </ul>
            <a href="/CB II.pdf" download className='ml-auto  sm:py-2 py-1 px-2 text-[10px] sm:text-[17px] rounded-md sm:px-3 bg-gradient-to-r from-btn to-btnhover hover:bg-gradient-to-r hover:from-btnhover hover:to-btn transition-colors ease-linear text-white hover:bg-smallTextColor w-fit flex justify-center items-center'  > <span>Master Plan</span> <span><GoDownload /></span></a>
                  </div>
                  <div class="sm:w-1/2">
                      <img src={e.img} alt="" />
                  </div>
              </div>
              
                ))
              }
        </div>

        <div className='mt-3 sm:mt-10 px-4 py-2 '>
          <h2 className='sm:text-[1.75rem] text-[1rem] font-[600] text-center text-primaryColor'>RUDA</h2>
          <p className='text-primaryColor sm:text-[1rem] text-[0.5rem] '>
            The Ravi Urban Development Authority (RUDA) is a forward-thinking initiative by the Punjab Government, designed
            to revitalize Lahore's grandeur and ensure long-term environmental sustainability. RUDA's ambitious plan involves
            constructing a sprawling riverside city stretching 46 kilometers from Ravi Siphon to Khud Pur. This meticulously
            planned development will integrate residential zones, commercial hubs, and industrial areas, fostering a thriving
            and diverse urban center.
          </p>
        </div>

        <div className=''>
          <img src={wave} alt='' />
          <div className='bg-nav px-4 py-2'>
            <h2 className='sm:text-[1.75rem] text-[1rem] font-[600] text-center text-primaryColor border-y-2 border-btnhover w-fit mx-auto'>
              Projects
            </h2>
            <div className='flex flex-col justify-center items-center mx-auto w-full sm:mt-7 mt-4 '>
              <Accordion className='w-full sm:w-[75%] bg-inherit'>
                <Accordion.Item eventKey="0" className='text-btnhover'>
                  <Accordion.Header ><h5 className='text-btnhover text-center overflow-y-hidden sm:text-[1.25rem] text-[0.50rem] font-[500]'>Chahar Bagh</h5></Accordion.Header>
                  <Accordion.Body className='chaharbagh'>
                    Discover Chahar Bagh, leading the way in urban development initiatives. Situated strategically, Chahar Bagh offers an enticing blend of residential plots, skyrises, midrises, condos, and commercial spaces, setting new benchmarks in modern living. Benefit from its unparalleled location near key transport hubs including Lahore Ring Road, Lahore Orange Train Head Office, and Lahore Sialkot Motorway, ensuring seamless connectivity and convenience.
                    <br />
                    <Link
                className='no-underline mx-auto sm:py-2 py-1 px-2 text-[10px] sm:text-[17px] rounded-md sm:px-3 bg-gradient-to-r from-btn to-btnhover hover:bg-gradient-to-r hover:from-btnhover hover:to-btn transition-colors ease-linear text-white hover:bg-smallTextColor text-center'
                to='/ruda'
                onClick={scrollTop}
              >
                Read More
              </Link>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header> <h5 className='text-btnhover text-center overflow-y-hidden sm:text-[1.25rem] text-[0.50rem] font-[500]'>Chahar Bagh Enclave</h5> </Accordion.Header>
                  <Accordion.Body chaharbaghEnclave>
                  Experience the epitome of luxurious living at Chahar Bagh Enclave, the newest addition to Ravi Urban Development Authority's (RUDA) prestigious portfolio. More than just a residential project, it embodies a visionary fusion of contemporary elegance and eco-consciousness, promising a lifestyle beyond compare. Select the perfect plot that aligns with your aspirations and embark on the journey to realize your dream home at Chahar Bagh Enclave. Explore now!
                  <br />
                  <Link
                className='no-underline mx-auto sm:py-2 py-1 px-2 text-[10px] sm:text-[17px] rounded-md sm:px-3 bg-gradient-to-r from-btn to-btnhover hover:bg-gradient-to-r hover:from-btnhover hover:to-btn transition-colors ease-linear text-white hover:bg-smallTextColor text-center'
                to='/ruda'
                onClick={scrollTop}
              >
                Read More
              </Link>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>

            <div className='sm:mt-10 mt-6 sm:flex justify-between items-center mb-5 grid grid-cols-1 mx-auto gap-3 w-[60%] sm:w-full'>
              <Link
                className='no-underline mx-auto sm:py-2 py-1 px-2 text-[10px] sm:text-[17px] rounded-md sm:px-3 bg-gradient-to-r from-btn to-btnhover hover:bg-gradient-to-r hover:from-btnhover hover:to-btn transition-colors ease-linear text-white hover:bg-smallTextColor text-center'
                to='/contact'
                onClick={scrollTop}
              >
                Book Appointment
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
