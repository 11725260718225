import React from 'react'
import { Helmet } from 'react-helmet';
import ceoProfile from '../assets/images-videos/profile linkedIn - web.png';
import { FaLinkedin } from "react-icons/fa";
import wave from '../assets/images-videos/wave (3).svg';



const About = () => {
  return (
    <div class="container mx-auto bg-white bg-opacity-55 ">
      <Helmet>
        <meta charSet="utf-8" />
        <title>About Us | Faizan Real Estate and Developers | Official Sales Partner with RUDA</title>
        <meta name="description" content="Faizan Real Estate & Developers - Your trusted partner for RUDA-approved properties in Lahore. We empower clients with expertise for seamless RUDA property sales, investment, and financing." />
      </Helmet>
  <div class="container mx-auto  mt-10  sm:px-5 px-2">
    <h1 class="mb-6 sm:mb-10 text-headingColor font-semibold sm:text-[2.5rem] text-[1.5rem] text-primaryColor leading-tight border-y-2 w-fit border-btn">
      Why <span class="sm:text-[1.75rem] text-[0.75rem] font-medium">Us?</span>
    </h1>
    <div class="sm:flex justify-center items-center gap-6 space-y-6 sm:space-y-0">
      <div class="px-4 py-6 bg-white rounded-lg sm:w-1/2 h-auto border-4 border-nav">
        <h2 class="sm:text-[1.75rem] text-[1rem] font-semibold text-center text-brd">Our Mission</h2>
        <p class="text-primaryColor sm:text-[1rem] text-sm leading-relaxed">
        Faizan Real Estate and Developers strives to be Lahore's leading real estate partner, specializing in RUDA-approved properties.  Our mission is to empower clients with the knowledge and resources necessary to achieve their property goals.  This includes providing a seamless experience for RUDA property sales and acquisition, along with expert investment advisory and financing assistance.  We are committed to fostering informed decision-making throughout the real estate journey, ensuring our clients secure their dream RUDA property with confidence.
        </p>
      </div>
      <div class="px-4 py-6 bg-white rounded-lg sm:w-1/2 h-auto border-4 border-nav">
        <h2 class="sm:text-[1.75rem] text-[1rem] font-semibold text-center text-brd">Our Vision</h2>
        <p class="text-primaryColor sm:text-[1rem] text-sm leading-relaxed">
        Faizan Real Estate and Developers envisions a future where our clients leverage RUDA properties to build a secure and prosperous future.  By providing comprehensive property management services, we go beyond the transaction to ensure clients maximize the return on their investment.  Our vision is to be a trusted advisor, guiding clients through every step of the RUDA property ownership process.  We aim to become synonymous with exceptional service, market expertise, and a dedication to exceeding client expectations in the Lahore RUDA real estate market.        </p>
      </div>
    </div>
    {/* <div class="sm:flex justify-center items-center gap-6 space-y-6 sm:space-y-0">
      <div class="px-4 py-6 bg-white rounded-lg sm:w-1/2 h-auto border-4 border-nav">
        <img src="" alt="" class="mx-auto" />
      </div>
      <div class="px-4 py-6 bg-white rounded-lg sm:w-1/2 h-auto border-4 border-nav">
        <img src="" alt="" class="mx-auto" />
      </div>
    </div> */}
  </div>

<div className="px-2 py-2 mt-16 h-fit">
<h3 className='sm:text-[2.25rem]  text-[1.25rem] font-[600] text-primaryColor  border-y-2 w-fit italic  border-btn'>Cheif Executive Officer</h3>
  <div className="mt-7">
{/* <img src={wave} className='-mb-1' alt='' /> */}
  <div className="flex flex-col md:flex-row py-6 justify-between sm:items-center bg-nav">
  <div className="sm:w-1/2 px-2">
    <h3 className=' sm:text-[1.75rem] text-[1rem] font-semibold text-center text-brd'>Umair Barkat Ullah</h3>
    <p className='text-center text-white sm:text-[1rem] text-sm leading-relaxed'>Entrepreneur | Real Estate Specialist and Consultant | CEO Faizan Real Estate and Developers</p>
    <p className='mx-auto flex text-[#0077B5] sm:px-2 sm:py-1 px-1 bg-white sm:text-[1rem] text-sm leading-relaxed justify-center items-center w-fit rounded-md'><span>View More on &nbsp;</span><a target='_blank' className='' href="https://www.linkedin.com/in/umair-barkat-ullah-a14837302/"><FaLinkedin className='text-[#0077B5] bg-white text-[1.5rem]' /></a></p>
  </div>
  <div className="sm:w-1/2">
    <img src={ceoProfile} className='sm:w-[50%] w-[70%] mx-auto' alt="" />
  </div>
  </div>
  </div>
</div>
</div>
  )
}
export default About