import React from 'react'
import { Helmet } from 'react-helmet';
import wave from '../assets/images-videos/wave (3).svg';

const PrivacyPolicy = () => {
  return (
    <div>
      <div class="container mx-auto bg-white bg-opacity-55 ">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Privacy Policy | Faizan Real Estate and Developers</title>
        <meta name="description" content="Faizan Real Estate & Developers - Privacy Policy. Learn about our commitment to safeguarding your personal information and ensuring your privacy." />
      </Helmet>
  <div class="container mx-auto  mt-10  sm:px-5 px-2">
    <h1 class="mb-6 sm:mb-10 text-headingColor font-semibold sm:text-[2.5rem] text-[1.5rem] text-primaryColor leading-tight border-y-2 w-fit border-btn">
      Privacy <span class="sm:text-[1.75rem] text-[0.75rem] font-medium">Policy</span>
    </h1>
    <div class="sm:flex justify-center items-center gap-6 space-y-6 sm:space-y-0">
      <div class="px-4 py-6 bg-white rounded-lg sm:w-full h-auto ">
        <h2 class="sm:text-[1.75rem] text-[1rem] font-semibold text-center text-brd">Introduction</h2>
        <p class="text-primaryColor sm:text-[1rem] text-sm leading-relaxed text-justify">
        At Faizan Real Estate and Developers, we are committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and safeguard your personal information. By using our services, you agree to the terms of this policy.
        </p>
      </div>
    </div>
    <div class="sm:flex justify-center items-center gap-6 space-y-6 sm:space-y-0 mt-6">
      <div class="px-4 py-6 bg-white rounded-lg sm:w-full h-auto ">
        <h2 class="sm:text-[1.75rem] text-[1rem] font-semibold text-center text-brd">Information Collection</h2>
        <p class="text-primaryColor sm:text-[1rem] text-sm leading-relaxed text-justify">
        We collect personal information that you voluntarily provide to us when you express an interest in obtaining information about us or our products and services, when you participate in activities on the website, or otherwise when you contact us. The personal information we collect can include your name, email address, phone number, and any other information you choose to provide.
        </p>
      </div>
    </div>
    <div class="sm:flex justify-center items-center gap-6 space-y-6 sm:space-y-0 mt-6">
      <div class="px-4 py-6 bg-white rounded-lg sm:w-full h-auto ">
        <h2 class="sm:text-[1.75rem] text-[1rem] font-semibold text-center text-brd">Use of Information</h2>
        <p class="text-primaryColor sm:text-[1rem] text-sm leading-relaxed text-justify">
        We use the information we collect or receive to communicate directly with you, to send you marketing and promotional communications, to deliver targeted advertising, and to improve our services. We do not share your personal information with third parties without your consent, except to comply with laws or respond to lawful requests and legal processes.
        </p>
      </div>
    </div>
    <div class="sm:flex justify-center items-center gap-6 space-y-6 sm:space-y-0 mt-6">
      <div class="px-4 py-6 bg-white rounded-lg sm:w-full h-auto ">
        <h2 class="sm:text-[1.75rem] text-[1rem] font-semibold text-center text-brd">Data Security</h2>
        <p class="text-primaryColor sm:text-[1rem] text-sm leading-relaxed text-justify">
        We implement appropriate technical and organizational measures to protect the personal information we collect and process. However, please also remember that we cannot guarantee that the internet itself is 100% secure. Although we will do our best to protect your personal information, transmission of personal information to and from our website is at your own risk.
        </p>
      </div>
    </div>
    <div class="sm:flex justify-center items-center gap-6 space-y-6 sm:space-y-0 mt-6">
      <div class="px-4 py-6 bg-white rounded-lg sm:w-full h-auto ">
        <h2 class="sm:text-[1.75rem] text-[1rem] font-semibold text-center text-brd">Your Privacy Rights</h2>
        <p class="text-primaryColor sm:text-[1rem] text-sm leading-relaxed text-justify">
        Depending on your location, you may have certain rights regarding your personal information, such as the right to access, correct, or delete the data we hold about you. To exercise these rights, please contact us using the information provided on our website.
        </p>
      </div>
    </div>
    
  </div>
</div>
    </div>
  )
}

export default PrivacyPolicy
