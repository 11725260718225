import React, { useState } from 'react'
import logo from '../assets/images-videos/logo faizan.png'
import { navBarData } from '../assets/data/data';
import { NavLink } from 'react-router-dom';
import { FaFacebookSquare, FaInstagramSquare, FaLinkedin } from "react-icons/fa";
import { MdOutlinePhone, MdOutlineEmail } from "react-icons/md";
import { RiMenuUnfoldLine } from "react-icons/ri";


const Navbar = () => {
  const [open, setOpen] = useState(false);

  const OpenMenu = () => {
      setOpen(!open);
  }
  return (
    <div className="">
    <div className='bg-primaryColor'>
        <div className="container mx-auto px-4 py-2">
            <div className="grid grid-cols-3 ">

                {/* //contact====================== */}
                <div className="sm:list-item hidden my-auto">
                    <a target='_blank' className='text- text-[15px] text-bg' href="tel:0331-6345525">0331-6345525</a><br />
                    <a target='_blank' className='text- text-[15px] text-bg' href="mailto:faizanrealestateanddevelopers@gmail.com">faizanrealestateanddevelopers@gmail.com</a>
                </div>

                {/* menu=================== */}
                <div className="sm:hidden my-auto">
                    <RiMenuUnfoldLine className='text-[25px] text-gray-50 cursor-pointer' onClick={OpenMenu} />
                </div>
                <div className="sm:w-[30%] w-[50%] mx-auto my-auto">
                    <NavLink to='/'>
                        <img src={logo} alt="" />
                    </NavLink>
                </div>

                {/* //socials=============== */}
                <div className="ml-auto my-auto">
                    <div className=" flex justify-start items-center gap-2">

                        <a target='_blank' href="https://www.facebook.com/faizanrealestate1/"><FaFacebookSquare className='sm:text-[25px] text-[17px] text-bg hover:text-btnhover cursor-pointer' /></a>
                        <a target='_blank' href="https://www.instagram.com/faizan_realestate/"><FaInstagramSquare className='sm:text-[25px] text-[17px] text-bg hover:text-btnhover cursor-pointer' /></a>
                        <a target='_blank' href="https://www.linkedin.com/company/faizan-real-estate-and-developers/"><FaLinkedin className='sm:text-[25px] text-[17px] text-bg hover:text-btnhover cursor-pointer' /></a>
                    </div>
                </div>
            </div>


        </div>
    </div>

    <div className="bg-nav hidden sm:flex">
    <div className="container mx-auto">
        <div className="flex justify-between items-center px-[10%] py-2">
        {
            navBarData.map(links=>(
                
                    <NavLink to={links.url} className='w-fit link no-underline'>
                        <h2 className='text-[17px] no-underline text-primaryColor  border-y-2 border-btnhover hover:border-0'>{links.title}</h2>
                    </NavLink>
                
            ))
        }
    </div>
    </div>
    </div>

    {
          open && <div className="sm:hidden bg-primaryColor bg-opacity-80 absolute w-[100%] z-40 min-h-screen" onClick={OpenMenu}>
                {/* // phone screen code */}
                <div className="bg-nav w-[70%] h-[90vh] flex flex-col justify-between">
                <div className="flex flex-col items-center  my-[10%] space-y-[20%]">
    {navBarData.map(link => (
        <NavLink key={link.url} to={link.url} className='text-[17px] no-underline text-primaryColor hover:text-btn border-b-2 ' onClick={OpenMenu}>
            {link.title}
        </NavLink>
    ))}
</div>

            <div className="flex flex-col justify-center items-center mb-7 border-b-2 border-b-btnhover ">
            
                    <a className='text-bg text-[70%] flex justify-center items-center gap-2 ' target='_blank' href="tel:0331-6345525"> <span><MdOutlinePhone className='text-btnhover' /></span >0331-6345525</a>
                    <a className='text-bg text-[70%] flex justify-center items-center gap-2' target='_blank' href="mailto:faizanrealestateanddevelopers@gmail.com"> <span><MdOutlineEmail className='text-btnhover' /></span>faizanrealestateanddevelopers@gmail.com</a>
                
            </div>
        </div>
            </div>
    }
</div>
  )
}

export default Navbar
